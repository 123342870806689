<template>
    <div>
        <approval-system
            :pending-liable-approvals="pendingLiableApprovals.system"
            :approvals="approvalSystem"
        />

        <approval-custom
            :pending-liable-approvals="pendingLiableApprovals.custom"
            @get-approvals="getApprovals"
            :approvals="approvalCustom"/>
    </div>
</template>

<script>
import { 
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
BCardText } from 'bootstrap-vue';
import ApprovalSystem from './ApprovalSystem.vue';
import ApprovalCustom from './ApprovalCustom.vue';
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BBadge,BButton,BRow,BCol,BCard,BCardText,ApprovalSystem,ApprovalCustom,
    },  
    directives: {
        Ripple,
    },
    methods: {
        checkPendingLiableApprovals(){
            this.$axios.get('/collab/approvals/check_pending_liable_approvals').then(resp=>{
                if(resp.data.status == "ok"){
                resp.data.data.pending_liable_approvals.forEach(approval =>{
                    if(approval.approval_type == 'system'){
                        this.pendingLiableApprovals.system.push(approval)
                    }else if(approval.approval_type == 'custom'){
                        this.pendingLiableApprovals.custom.push(approval)
                    }
                })
                var pendingCount = resp.data.data.pending_liable_approvals.length
                sessionStorage.setItem('pending_liable_approvals_count', pendingCount)
                }
            })
        },
        getApprovals(){
            this.$axios.get('/collab/approvals/get_approvals').then((resp) =>{
                this.approvals = resp.data.data.approvals
            })
        },
    },
    data(){
        return{
            pendingLiableApprovals: {
                system: [],
                custom: []
            },
            approvals:[],
        }
    },
    computed:{
        approvalSystem(){
            return this.approvals.filter(approval => approval.approval_type == 'system')
        },
        approvalCustom(){
            return this.approvals.filter(approval => approval.approval_type == 'custom')
        }
    },
    created(){
        this.checkPendingLiableApprovals()
        this.getApprovals()
    }
}

</script>