<template>
    <div>
        <h5 class="mb-1">自定义审批流</h5>
        <b-button class="mb-2" variant="primary" size="sm" @click="toAddNewPage">添加自定义审批流</b-button>
        <b-row>
            <b-col v-for="approval in approvals"
                md="6"
                lg="4"
            >
                <b-card
                    :title="approval.approval_name"
                    class="text-left"
                    footer-class="text-muted"
                    :footer="approval.approval_footer"
                >
                <b-card-text>
                    <p>{{ approval.approval_description  }}</p>
                    <span>管理员：
                        <b-badge class="mr-1" v-for="manager in approval.managers" variant="light-primary">{{ manager }}</b-badge>
                    </span>
                </b-card-text>
                <span>
                <b-button
                    class="mr-1"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    @click="toApprovalPage(approval.approval_id)"
                >
                    进入审批流<b-badge v-if="mapPendingCount(approval.approval_id)" class="ml-1" variant="danger" pill>{{ mapPendingCount(approval.approval_id) }}</b-badge>
                </b-button>
                </span>

                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { 
    BButton,
    BRow,
    BCol,
    BCard,
    BBadge,
BCardText } from 'bootstrap-vue';
import Ripple from 'vue-ripple-directive'

export default {
    components: {
        BBadge,BButton,BRow,BCol,BCard,BCardText
    },  
    directives: {
        Ripple,
    },
    props: {
        approvals: {
            type: Array,
            default: () => []
        },
        pendingLiableApprovals: {
            type: Array,
            default: () => []
        },
    },
    methods: {
        toAddNewPage(){
            this.$router.push({name: 'collab-approvals-add-new-view'})
        },
        toApprovalPage(id){
            this.$router.push({name: 'collab-approval-view', params: {id: id}})
        },
        mapPendingCount(approvalId){
            let count = 0
            this.pendingLiableApprovals.forEach(pendingApproval => {
                if(pendingApproval.approval_id == approvalId){
                    count ++
                }
            })
            return count
        },
    }
}

</script>